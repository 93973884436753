import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { clearLayoutAction, setLayoutAction } from '../state/layout.reducer';
import SEO from '../components/seo';
import { Loading } from '../components/common/loading';
import {
	callAWaiterAction, clearDivideBillAction, clearNotificationVisibleAction, fetchOrderDataByIdAction,
} from '../state/order.reducer';
import BillWrapper from '../components/order/billWrapper';
import { getQueryParam } from '../utils/queryParams';
import { LINKS } from '../constants/links';
import { AppContainer } from '../components/common/appContainer';
import { clearTableOrdersAction } from '../state/orders.reducer';

const StyledActualizationNotification = styled.div`
	background-color: ${({ theme }) => theme.colors.brandPurple};
	border-bottom-left-radius: 20px;
	border-top-left-radius: 20px;
	color: #fff;
	display: inline-block;
	font-size: 1rem;
	font-weight: 700;
	line-height: 31px;
	padding-left: 20px;
	padding-right: 10px;
	position: fixed;
	right: 0;
	top: 91px;
	z-index: 9999;

	span {
		padding-left: 20px;
	}
`;

const OrderPage = () => {
	let interval = null;
	const intervalTime = 3000;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const order = useSelector((state) => state.order);
	const {
		data, customLogo, error, fetched, loadedOnce, actualizedNotificationVisible, waiterCalledNotificationVisible, loaderVisible,
	} =		order;
	const { isPaid } = data;
	const topOptions = [];
	const orderId = getQueryParam('orderId');

	const clearFetchInterval = () => {
		if (interval) {
			clearInterval(interval);
			interval = null;
		}
	};

	const clearNotificationMessage = () => {
		dispatch(clearNotificationVisibleAction());
	};

	const fetchBillDetails = () => {
		dispatch(fetchOrderDataByIdAction(orderId));
	};

	const setFetchInterval = () => {
		interval = setInterval(fetchBillDetails, intervalTime);
	};

	const callAWaiter = () => {
		dispatch(callAWaiterAction(orderId));
	};

	const openMenu = () => {
		navigate(`${LINKS.RESTAURANT}/${data.restaurant.slug}${LINKS.MENU}`);
	};

	useEffect(() => {
		clearFetchInterval();
		fetchBillDetails();
		setFetchInterval();

		return () => {
			clearFetchInterval();
			dispatch(clearTableOrdersAction());
		};
	}, []);

	useEffect(() => {
		dispatch(
			setLayoutAction({
				backTo: isPaid ? LINKS.ORDER_HISTORY : null,
				customLogoSrc: customLogo,
				// eslint-disable-next-line max-len
				customLogoUrl: data?.restaurantTable?.restaurant?.slug ? `${LINKS.RESTAURANT}/${data?.restaurantTable?.restaurant?.slug}` : null,
				topOptions: topOptions.length ? topOptions : null,
			}),
		);
		return () => {
			dispatch(clearLayoutAction());
			dispatch(clearDivideBillAction());
		};
	}, [isPaid]);

	if (!isPaid) {
		topOptions.push({
			label: t('order.callWaiter'),
			onClick: callAWaiter,
		});
		topOptions.push({
			label: t('restaurant.restaurantMenu'),
			onClick: openMenu,
		});
	}

	return (
		<>
			<SEO title={t('order.title')} />
			{actualizedNotificationVisible && (
				<StyledActualizationNotification onClick={clearNotificationMessage}>
					<span>{t('order.billActualized')}</span>
					<span className="close">X</span>
				</StyledActualizationNotification>
			)}
			{waiterCalledNotificationVisible && (
				<StyledActualizationNotification onClick={clearNotificationMessage}>
					<span>{t('order.waiterCalled')}</span>
					<span className="close">X</span>
				</StyledActualizationNotification>
			)}
			{/* {loaderVisible && <Loading text={t("order.loadingText")} />} */}
			<AppContainer>{!error && (fetched || loadedOnce) && <BillWrapper orderId={orderId} />}</AppContainer>
		</>
	);
};

export default OrderPage;
