import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { fetchPaymentUrlAction } from '../../state/payment.reducer';
import AppPageHeader from '../common/texts/appPageHeader';
import { LINKS } from '../../constants/links';
import { LoadingCircle } from '../common/loading';
import { isUserLogged, setNip } from '../../utils/localStorage';
import { clearCookiesBeforeLogin, getCookiesBeforeLogin, setCookiesBeforeLogin } from '../../utils/cookies';
import { PAYMENT_TYPES } from '../../constants/paymentTypes';
import { theme } from '../../constants/theme';
import AppButton from '../common/button';
import ThankYouForComing from './thankYouForComing';
import Regulation from './regulation';
import TotalPrice from './totalPrice';
import ChooseTip from './tips/choose-tip';
import PosIntegrationBill from './posIntegrationBill';
import GetInvoice from './getInvoice';
import OrderHistoryRestaurantHeader from './orderHistoryRestaurantHeader';
import OrderHistoryRating from './orderHistoryRating';

const useStyles = makeStyles(() => ({
	billWrapper: {
		padding: '0 0 30px',
	},
	buttonsWrapper: {
		paddingTop: 45,
		textAlign: 'center',
	},
	restpayButton: {
		marginVottom: 25,
	},
	billLoadingWrapper: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	errorWrapper: {
		color: theme.colors.fontRed,
		fontWeight: 600,
		padding: 20,
		textAlign: 'center',
	},
	billNotEnteredText: {
		fontSize: '1.6rem',
		fontWeight: 300,
		lineHeight: '2rem',
		margin: '30px 0 0',
		textAlign: 'center',
	},
}));

const BillWrapper = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const {
		data, loading: issueAnInvoice, invoiceNip, invoiceNipValid, billEntered,
	} = useSelector((state) => state.order);
	const { loading: paymentLoading } = useSelector((state) => state.payment);
	const {
		total, tip, id, paymentStatus,
	} = data;

	const handleOperationAfterLogin = () => {
		const {
			orderId, price, tip, paymentType, nip,
		} = getCookiesBeforeLogin();
		clearCookiesBeforeLogin();
		if (!orderId || !price || !paymentType || !isUserLogged()) {
			return;
		}
		dispatch(fetchPaymentUrlAction(orderId, price, parseInt(tip, 10), paymentType, nip));
	};

	const handleNotLogged = (orderId, price, tip, paymentType, nip) => {
		setCookiesBeforeLogin(orderId, price, tip, paymentType, nip);
		navigate(LINKS.LOGIN);
	};

	const generatePaymentUrl = (paymentType) => {
		const nip = issueAnInvoice && invoiceNipValid && invoiceNip ? invoiceNip : '';
		const isLogged = isUserLogged();
		if (nip) {
			setNip(nip);
		}
		if (!isLogged) {
			handleNotLogged(id, total, tip * 100, paymentType, nip);
		} else {
			dispatch(fetchPaymentUrlAction(id, total * 100, tip, paymentType, nip));
		}
	};

	useEffect(() => {
		handleOperationAfterLogin();
	}, []);

	return (
		<div className={classes.billWrapper}>
			{!paymentLoading && (
				<>
					{paymentStatus === 'SUCCESS' && <OrderHistoryRestaurantHeader />}
					{paymentStatus !== 'SUCCESS' && (
						<ThankYouForComing
							billEntered={billEntered}
							data={data}
						/>
					)}
					{billEntered && (
						<>
							<AppPageHeader>{t('order.billHeader')}</AppPageHeader>
							<PosIntegrationBill paymentStatus={paymentStatus} />
							{paymentStatus === 'FAILURE' && <div className={classes.errorWrapper}>{t('order.paymentFailed')}</div>}
							{paymentStatus !== 'SUCCESS' && paymentStatus !== 'PENDING' && (
								<>
									<ChooseTip />
									<TotalPrice />
									<GetInvoice />
									<div className={classes.buttonsWrapper}>
										<AppButton
											as="button"
											onClick={() => generatePaymentUrl(PAYMENT_TYPES.STRIPE_CARD)}
										>
											{t('order.payButton')}
										</AppButton>
									</div>
									<Regulation />
								</>
							)}
						</>
					)}
					{paymentStatus === 'SUCCESS' && <OrderHistoryRating />}
					{!billEntered && (
						<div className={classes.billLoadingWrapper}>
							<p className={classes.billNotEnteredText}>{t('order.billNotEnteredYet')}</p>
							<AppPageHeader>{t('order.billHeader')}</AppPageHeader>
							<LoadingCircle />
						</div>
					)}
				</>
			)}
			{paymentLoading && (
				<div className={classes.billLoadingWrapper}>
					<AppPageHeader>{t('order.paymentProcessing')}</AppPageHeader>
					<LoadingCircle />
				</div>
			)}
		</div>
	);
};

export default BillWrapper;
