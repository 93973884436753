import React from 'react';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import Rater from 'react-rater';
import * as moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { ImagesSlider } from '../common/imagesSlider';
import i18n from '../../utils/i18n';

const useStyles = makeStyles(() => ({
	ratingWrapper: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 18,
		padding: '8px 0',
		width: '50%',
	},
	dataWrapper: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		textDecoration: 'none',
	},
	dateString: {
		fontSize: 11,
		textDecoration: 'none',
	},
}));

const PaymentHistoryRestaurantHeader = () => {
	const classes = useStyles();
	const orderData = useSelector((state) => state.order.data);
	const restaurant = orderData.restaurant || null;

	const language = i18n.language.slice(0, 2).toLowerCase();
	const date = moment(orderData.updatedAt).locale(language).format('DD/MM/YYYY, [godz] HH:mm');
	const rating = orderData.ratings?.length ? orderData.ratings[0].rating : 0;

	const sliderImages = restaurant?.images?.filter((image) => image.type === 'PROFILE');
	return (
		restaurant && (
			<>
				<Link to={`/restaurant/${restaurant.slug}`}>
					<ImagesSlider images={sliderImages} />
				</Link>
				<div className={classes.dataWrapper}>
					<div className={classes.ratingWrapper}>
						<Rater
							interactive={false}
							rating={rating}
							total={5}
						/>
					</div>
					<span className={classes.dateString}>{date}</span>
				</div>
			</>
		)
	);
};

export default PaymentHistoryRestaurantHeader;
